import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { memo, useContext } from "react"
import Counter from "./Counter"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 80%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 120px 0px 150px;
    align-self: center;

    @media ${device.lg} {
        width: 90%;
    }

    @media ${device.md} {
        width: 80%;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    position: relative;
    gap: 100px;

    @media ${device.xl} {
        gap: 50px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }
`

const DataContainer = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    border-top: 2px solid #ccc;
    padding: 30px 0px;

    @media ${device.md} {
        padding: 30px 0px 0px;
        align-items: center;
    }

`

const TextInfo = styled.p<{ color: string }>`
    color: #222;
    color: ${({ color }) => (color)};

    line-height: 25px;
    margin-bottom: 15px;

    @media ${device.lg} {
        font-size: 14px;
    }
    
    @media ${device.md} {
        text-align: center;
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const Title = styled.div<{ color: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 60px;

    h2 {
        width: 100%;
        font-size: 38px;
        font-weight: 600;
        color: #2E2D2D;
        color: ${({ color }) => (color)};
        text-align: start;

        @media ${device.md} {
            font-size: 28px;
            text-align: center;

        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }
    
    @media ${device.sm} {
        margin-bottom: 50px;
    }

`

const DescriptionsComponent = () => {

    const { t } = useTranslation();

    const {
        theme: { colors },
    } = useContext(ThemeContext);

    return (

        <Container>

            <Title
                className="utils__fade-in-fast-top"
                color={colors.text}
            >
                <h2>
                    {t('description.title.1')}
                    <br />
                    {t('description.title.2')}
                    <br />
                    {t('description.title.3')}
                </h2>
            </Title>


            <ContentContainer>

                <DataContainer key={t('description.item.subtitle.1')} className="utils__fade-in-fast-top">

                    <Counter targetNumber={t('description.item.number.1')} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                        color={colors.text_light}
                    >
                        {t('description.item.subtitle.1')}
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        color={colors.text_light}
                    >
                        {t('description.item.description.1')}
                    </TextInfo>

                </DataContainer>

                <DataContainer key={t('description.item.subtitle.2')} className="utils__fade-in-fast-top">

                    <Counter targetNumber={t('description.item.number.2')} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                        color={colors.text_light}
                    >
                        {t('description.item.subtitle.2')}
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        color={colors.text_light}
                    >
                        {t('description.item.description.2')}
                    </TextInfo>

                </DataContainer>

                <DataContainer key={t('description.item.subtitle.3')} className="utils__fade-in-fast-top">

                    <Counter targetNumber={t('description.item.number.3')} />

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{ fontWeight: 600 }}
                        color={colors.text_light}
                    >
                        {t('description.item.subtitle.3')}
                    </TextInfo>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        color={colors.text_light}
                    >
                        {t('description.item.description.3')}
                    </TextInfo>

                </DataContainer>

            </ContentContainer>

        </Container>

    )
}

const Descriptions = memo(DescriptionsComponent);

export default Descriptions;