import { memo, useContext } from "react";
import styled from "styled-components"
import { ThemeContext } from "../contexts/theme/ThemeContext";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;

`

const ScreenContainerComponent = ({ id, children }: { id?: string, children?: React.ReactNode }) => {

    const {
        theme: { colors },
    } = useContext(ThemeContext);

    return (

        <Container
            id={id}
            style={{ background: colors.background }}
        >

            {children}

        </Container>

    )
}

const ScreenContainer = memo(ScreenContainerComponent);

export default ScreenContainer;