import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { ThemeContext } from "../contexts/theme/ThemeContext";

interface CounterProps {
    targetNumber: number;
    duration?: number;  // Duración total de la animación en milisegundos (default 3000 ms)
}

const Counter: React.FC<CounterProps> = ({ targetNumber, duration = 3000 }) => {
    const [currentValue, setCurrentValue] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef<HTMLDivElement>(null);

    const {
        theme: { colors },
    } = useContext(ThemeContext);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setIsVisible(true);
                    }, 300);
                }
            },
            { threshold: 0.5 } // El 50% del componente debe estar visible para activar la animación
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            const startTime = performance.now();

            const animateCounter = (currentTime: number) => {
                const elapsedTime = currentTime - startTime;
                const progress = elapsedTime / duration; // Porcentaje de la animación

                // Ease-out effect: desaceleración al final (usando una función cuadrática)
                const easedProgress = 1 - Math.pow(1 - progress, 3);

                // Calculamos el valor del contador según el progreso suavizado
                const newValue = Math.round(targetNumber * easedProgress);

                if (elapsedTime < duration) {
                    setCurrentValue(newValue);
                    requestAnimationFrame(animateCounter); // Continuamos la animación
                } else {
                    setCurrentValue(targetNumber); // Aseguramos que al final sea el valor exacto
                }
            };

            requestAnimationFrame(animateCounter); // Iniciar animación
        }
    }, [isVisible, targetNumber, duration]);

    return <StyledCounter className="utils__fade-in-fast-top" color={colors.text} ref={counterRef}>{currentValue}+</StyledCounter>;
};

// Estilos con styled-components
const StyledCounter = styled.div<{ color: string }>`
    color: #222;
    color: ${({ color }) => (color)};
    
    font-size: 72px;
    font-weight: 500;
    
    @media ${device.md} {
        text-align: center;
        font-size: 60px;
    }

    @media ${device.sm} {
        font-size: 50px;
    }
`;

export default Counter;
