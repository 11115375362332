import styled from "styled-components"
import Title from "./Title"
import { device } from "../styles/stylesConfig"
import { memo, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { MainSectionProps } from "../interfaces/TextsInterfaces"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 100%;
    min-height: 70vh;
    padding-top: 20vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border-bottom: 1px solid rgba(27, 48, 100, 0.3);

    
    @media ${device.lg} {
        padding-top: 15vh;
    }
`

const ContentContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    @media ${device.lg} {
        width: 100%;

        & > div > h1 {
            text-align: center !important;
        }

    }
`

const DataContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media ${device.lg} {
        width: 80%;
        height: auto;
        grid-template-columns: 1fr;
    }
    
`

const Image = styled.img`
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    margin-right: 25px;

    
    @media ${device.lg} {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-right: 0px;
    }

`

const Info = styled.p`
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #2e2d2d;
    line-height: 35px;
    margin-left: 25px;
    font-size: 14px;


    @media ${device.xl} {
        line-height: 29px;
    }

    @media ${device.lg} {
        margin-left: 0px;
        margin-top: 50px;
        line-height: 30px;
        text-align: center;
    }

    @media ${device.md} {
        margin-top: 50px;
        line-height: 25px;
        font-size: 14px;
    }

    @media ${device.sm} {
        margin-top: 30px;
        line-height: 20px;
        font-size: 12px;
    }

`

const SystemsContainer = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 100px;
    max-width: 800px;
    
    @media ${device.lg} {
        max-width: 500px;
    }


    @media ${device.sm} {
        margin-top: 70px;
    }
`

const System = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SystemTitle = styled.div`
    text-transform: uppercase;
    color: #004bb5;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;

    @media ${device.lg} {
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media ${device.md} {
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media ${device.sm} {
        font-size: 14px;
        margin-bottom: 20px;
    }

    @media ${device.xs} {
        font-size: 12px;
        margin-bottom: 20px;
    }
`

const SystemSubtitle = styled.div`
    text-transform: uppercase;
    color: #2E2D2D;
    font-size: 13px;

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }
`

const Icon = styled.img`
    width: 120px;
    height: auto;
    object-fit: contain;

    @media ${device.lg} {
        width: 100px;
    }

    @media ${device.md} {
        width: 90px;
    }

    @media ${device.sm} {
        width: 80px;
    }

    @media ${device.xs} {
        width: 60px;
    }

`


const IconBigger = styled.img`
    width: 240px;
    height: auto;
    object-fit: contain;

    @media ${device.lg} {
        width: 220px;
    }

    @media ${device.md} {
        width: 200px;
    }

    @media ${device.sm} {
        width: 160px;
    }

    @media ${device.xs} {
        width: 100px;
    }

`



const ProductIntroComponent = ({ title, image, description, systems }: MainSectionProps) => {

    const {
        theme: { colors, dark },
    } = useContext(ThemeContext);

    const location = useLocation();

    const { t } = useTranslation();

    useEffect(() => {

        allAnimations();

        return () => {
            allAnimations()
        };

    }, [location.pathname])

    return (

        <Container id="product">

            <ContentContainer>

                <Title
                    title={title}
                    titleColor="#004bb5"
                    textAlign="start"
                    titleStyle={{ width: '100%', maxWidth: 1100 }}
                />

                <DataContainer>

                    <Image src={require(`../images/${image}`)} alt={title} loading="lazy" className="utils__fade-in-fast-top" />

                    <Info style={{ color: colors.text }} className="utils__fade-in-fast-top">{description}</Info>

                </DataContainer>

                <SystemsContainer>

                    {
                        systems.map((system) => {

                            return (

                                <>

                                    {
                                        system.title.includes('REHAU')
                                            ? <IconBigger src={require(`../images/${dark ? system.imageDark : system.image}`)} alt={system.title} className="utils__fade-in-fast-top" />
                                            : <Icon src={require(`../images/${dark ? system.imageDark : system.image}`)} alt={system.title} className="utils__fade-in-fast-top" />
                                    }

                                    <System key={system.title}>

                                        <SystemTitle className="utils__fade-in-fast-top">{t('product.profile.types')}</SystemTitle>

                                        {
                                            system.types.map((system) => {

                                                return <SystemSubtitle style={{ color: colors.text }} className="utils__fade-in-fast-top" key={system}>{system}</SystemSubtitle>

                                            })
                                        }

                                    </System>

                                </>

                            )
                        })
                    }

                </SystemsContainer>

            </ContentContainer>


        </Container>

    )
}

const ProductIntro = memo(ProductIntroComponent);

export default ProductIntro;
