import { memo, useContext, useState } from "react";
import AppLogoComponent from "./AppLogoComponent"
import { css, styled } from 'styled-components';
import { device } from "../styles/stylesConfig";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import QuotatorButton from "./QuotatorButton";
import CountryFlags from "./CountryFlags";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguajeSelect";
import { ThemeContext } from "../contexts/theme/ThemeContext";

const Container = styled.div`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    box-shadow: 0px 4px 12px 0px rgba(27, 48, 100, 0.5);
    backdrop-filter: blur(0px);
    background-color: transparent;
    padding: 7.3px 0px;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    border-bottom: 1px solid #004bb5;

    @media ${device.lg} {
        height: 70px;
        padding: 7.3px 0px;
    }

`

const MenuDiv = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
        width: 80%;
        height: auto;
        flex-direction: row;
    }

        @media ${device.xs} {
        width: 90%;
    }


`

const NavDiv = styled.div`
    width: 600px;
    display: flex;
    color: black;
    align-items: center;
    justify-content: space-between;
    
    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`

const NavButton = styled.button<{ color: string }>`
    color: #111;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
        content: '';
        height: 5px;
        width: 0%;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: #ff0c19;
        transition: width 0.3s ease;
    }

    &:focus,
    &:hover {
        outline: none;

        &::before {
            width: 100%;
        }

        & > div {
            visibility: visible !important;
        }
        
    }

    ${({ color }) => color && css`
        color: ${color};
    `}

`


const ButtonWrapper = styled.div`
  position: relative;

  &:hover button {
        outline: none;

        &::before {
            width: 100%;
        }

        & > div {
            visibility: visible !important;
        }
        
    }

    &:hover img {
        transform: rotate(-90deg);
    }
`;

const DropdownMenu = styled.div<{ isVisible: boolean, dark: string }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  box-shadow: ${({ dark }) => (dark === 'true' ? '0px 8px 16px rgba(255, 255, 255, 0.2)' : '0px 8px 16px rgba(0, 0, 0, 0.2)')};
  border-radius: 4px;
  z-index: 1;
  width: auto;
  
`;

const DropdownItem = styled.button<{ color: string, backgroundColor: string, backgroundColorHover: string }>`
    background-color: white;
    border: none;
    padding: 15px 20px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    color: #333;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;

    &:last-of-type {
        border-bottom: unset
    }

  &:hover {
    background-color: #f1f1f1;
  }

  
  ${({ color }) => color && css`
        color: ${color};
    `}

    ${({ backgroundColor }) => backgroundColor && css`
        background-color: ${backgroundColor};

        &:hover {
            background-color: ${backgroundColor}99;
        }
    `}

    ${({ backgroundColorHover }) => backgroundColorHover && css`

        &:hover {
            background-color: ${backgroundColorHover};
        }
    `}
`;

const ArrowMenu = styled.img`
    width: 18px;
    height: 15px;
    position: absolute;
    top: 8.7px;
    right: -15px;
    transition: all 0.3s ease-in-out;

`;

export const HeaderComponent = () => {

    const {
        theme: { colors, dark },
        setDarkTheme,
        setLightTheme,
    } = useContext(ThemeContext);

    const [showMenu, setShowMenu] = useState(false);

    const [isMenuVisible, setMenuVisible] = useState(false);

    const {
        handleClickGoHome,
        handleClickGoHomeInstant,
        handleClickGoAbout,
        handleClickGoAboutInstant,
        handleClickGoProducts,
        handleClickGoProductInstant,
        handleClickGoProductsInstant,
        handleClickGoJobs,
        handleClickGoJobsInstant,
        handleClickGoContact,
        handleClickGoContactInstant,
        handleClickGoQuotator,
        handleClickGoQuotatorInstant,
    } = useAnimations()

    const history: any = useHistory();

    const location = useLocation();

    const { handleLogEvent } = useLogEvent();

    const { t } = useTranslation();

    const handleClickGoTo = async (goTo: '/productos/pvc' | '/productos/aluminio' | '/productos/herrajes') => {

        await history.push('/');
        await history.push(goTo);

        setTimeout(() => {
            handleClickGoProductInstant()
            handleClickGoProductsInstant()
        }, 20);

    }


    return (

        <Container
            className="utils__fade-in-fast-bottom"
            style={{ backgroundColor: colors.background_light }}
        >

            <MenuDiv>

                <AppLogoComponent />

                <CountryFlags style={{ visibility: "hidden" }} device="desktop" />

                <NavDiv>

                    <NavButton
                        color={colors.text}
                        onClick={async (e: any) => {

                            if (location.pathname !== "/") {
                                await history.push('/');

                                setTimeout(() => {
                                    handleClickGoHomeInstant()
                                }, 10);
                            } else {
                                handleClickGoHome();
                            }

                            handleLogEvent('click_boton_inicio_menu');

                            e.target?.blur()

                        }}>
                        {t('header.home')}
                    </NavButton>

                    <NavButton
                        color={colors.text}
                        onClick={async (e: any) => {

                            if (location.pathname !== "/nosotros") {
                                await history.push('/nosotros');

                                setTimeout(() => {
                                    handleClickGoAboutInstant()
                                }, 10);
                            } else {
                                handleClickGoAbout();
                            }

                            handleLogEvent('click_boton_nosotros_menu');

                            e.target?.blur()

                        }}>
                        {t('header.about')}
                    </NavButton>

                    <ButtonWrapper
                        onMouseEnter={() => setMenuVisible(true)}
                        onMouseLeave={() => setMenuVisible(false)}
                    >

                        <NavButton
                            color={colors.text}
                            onClick={async (e: any) => {

                                if (location.pathname !== "/productos") {
                                    await history.push('/productos');

                                    setTimeout(() => {
                                        handleClickGoProductsInstant()
                                    }, 10);
                                } else {
                                    handleClickGoProducts();
                                }

                                handleLogEvent('click_boton_productos_menu');

                                e.target?.blur()

                            }}>
                            {t('header.products')}
                            <ArrowMenu src={require(`../images/arrow-menu-${dark ? 'white' : 'black'}.png`)} alt='' />
                        </NavButton>
                        <DropdownMenu isVisible={isMenuVisible} dark={dark.toString()}>
                            <DropdownItem color={colors.text} backgroundColor={colors.background_light} backgroundColorHover={colors.background} onClick={() => { handleClickGoTo('/productos/pvc') }}>{t('header.products.pvc')}</DropdownItem>
                            <DropdownItem color={colors.text} backgroundColor={colors.background_light} backgroundColorHover={colors.background} onClick={() => { handleClickGoTo('/productos/aluminio') }}>{t('header.products.aluminio')}</DropdownItem>
                            <DropdownItem color={colors.text} backgroundColor={colors.background_light} backgroundColorHover={colors.background} onClick={() => { handleClickGoTo('/productos/herrajes') }}>{t('header.products.ironworks')}</DropdownItem>
                        </DropdownMenu>
                    </ButtonWrapper>



                    <NavButton
                        color={colors.text}
                        onClick={async (e: any) => {

                            if (location.pathname !== '/obras') {

                                history.push('/obras');

                                setTimeout(() => {
                                    handleClickGoJobsInstant()
                                }, 10);

                            } else {

                                handleClickGoJobs()

                            }

                            handleLogEvent('click_boton_obras_menu');

                            e.target?.blur()


                        }}>
                        {t('header.works')}
                    </NavButton>

                    <NavButton
                        color={colors.text}
                        onClick={async (e: any) => {

                            if (location.pathname !== '/contacto') {

                                history.push('/contacto');

                                setTimeout(() => {
                                    handleClickGoContactInstant()
                                }, 10);

                            } else {

                                handleClickGoContact()

                            }

                            handleLogEvent('click_boton_contacto_menu');

                            e.target?.blur()

                        }}>
                        {t('header.contact')}
                    </NavButton>

                </NavDiv>

                <LanguageSelect />

                <QuotatorButton onClick={async () => {

                    if (location.pathname !== '/cotizador') {

                        history.push('/cotizador');

                        setTimeout(() => {
                            handleClickGoQuotatorInstant()
                        }, 10);

                    } else {

                        handleClickGoQuotator()

                    }

                    handleLogEvent('click_boton_cotizador_menu');

                }} />

                <MenuButton showMenu={showMenu} setShowMenu={setShowMenu} />

            </MenuDiv>

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

        </Container>

    )
}

const Header = memo(HeaderComponent);

export default Header;
