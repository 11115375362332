import React, { useContext, useState } from "react";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../contexts/theme/ThemeContext";

// Datos de los idiomas
const LANGUAGES = [
  { code: "es", label: "ES", flag: "https://flagcdn.com/w40/ar.png" },
  { code: "en", label: "EN", flag: "https://flagcdn.com/w40/us.png" },
];

// Styled Components
const DropdownWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-right: 5px;
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #000;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: #007bff;
  }

  @media ${device.md} {
        font-size: 14px;
    }
`;

const FlagIcon = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 7px;
  border-radius: 3px;
`;

const DropdownList = styled.ul<{ dark: string }>`
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #111;
  color: #000;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  box-shadow: ${({ dark }) => (dark === 'true' ? '0px 8px 16px rgba(255, 255, 255, 0.25)' : '0px 8px 16px rgba(0, 0, 0, 0.25)')};

`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
  transition: all .3s ease;

  &:focus,   
  &:hover {
    background-color: #eee;
  }

  @media ${device.md} {
        font-size: 14px;
    }

  & > img {
    margin-right: 10px;
    border-radius: 3px;
    margin-bottom: 2px;
  }
`;

export const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const {
    theme: { colors, dark },
  } = useContext(ThemeContext);

  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES.find((value) => { return i18n.language === value.code }) ?? LANGUAGES[0]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);


  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  const handleSelect = (language: any) => {
    changeLanguage(language.code)
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  return (
    <DropdownWrapper>
      {/* Idioma seleccionado */}
      <SelectedOption
        style={{ color: colors.text }}
        onClick={(e: any) => {
          toggleDropdown()
          e.stopPropagation();
        }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FlagIcon src={selectedLanguage?.flag} alt={selectedLanguage.label} />
          {selectedLanguage.label}
        </div>
      </SelectedOption>

      {/* Lista desplegable */}
      {isOpen && (
        <DropdownList dark={dark.toString()}>
          {LANGUAGES.map((language) => (
            <DropdownItem
              style={{ color: colors.text, backgroundColor: colors.background_light }}
              key={language.code}
              onClick={(e: any) => {
                handleSelect(language);
                e.stopPropagation();
              }}
            >
              <img src={language.flag} alt={language.label} width="20" />
              {language.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default LanguageSelect;
