
const handleClickGoSection = (id: string, trasition: 'smooth' | 'auto') => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: trasition });
    }
}

export const useAnimations = () => {

    // HOME

    const handleClickGoHome = () => { handleClickGoSection("home", "smooth") }

    const handleClickGoHomeInstant = () => { handleClickGoSection("home", "auto") }

    // ABOUT

    const handleClickGoAbout = () => { handleClickGoSection("about", "smooth") }

    const handleClickGoAboutInstant = () => { handleClickGoSection("about", "auto") }

    // PRODUCTS

    const handleClickGoProducts = () => { handleClickGoSection("products", "smooth") }

    const handleClickGoProductsInstant = () => { handleClickGoSection("products", "auto") }

    // PROUCT

    const handleClickGoProduct = () => { handleClickGoSection("product", "smooth") }

    const handleClickGoProductInstant = () => { handleClickGoSection("product", "auto") }

    // JOBS

    const handleClickGoJobs = () => { handleClickGoSection("jobs", "smooth") }

    const handleClickGoJobsInstant = () => { handleClickGoSection("jobs", "auto") }

    // CONTACT

    const handleClickGoContact = () => { handleClickGoSection("contact", "smooth") }

    const handleClickGoContactInstant = () => { handleClickGoSection("contact", "auto") }

    // QUOTATOR

    const handleClickGoQuotator = () => { handleClickGoSection("quotator", "smooth") }

    const handleClickGoQuotatorInstant = () => { handleClickGoSection("quotator", "auto") }


    return {
        handleClickGoHome,
        handleClickGoHomeInstant,
        handleClickGoAbout,
        handleClickGoAboutInstant,
        handleClickGoProducts,
        handleClickGoProductsInstant,
        handleClickGoProduct,
        handleClickGoProductInstant,
        handleClickGoJobs,
        handleClickGoJobsInstant,
        handleClickGoContact,
        handleClickGoContactInstant,
        handleClickGoQuotator,
        handleClickGoQuotatorInstant
    }

}