import { Suspense, lazy, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import ScreenContainer from "../components/ScreenContainer"
import LoadingComponent from "../components/LoadingComponent"
import WorkModal from "../components/WorkModal"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const JobsGrid = lazy(() => import("../components/JobsGrid"));

export const JobsScreen = () => {

    const location = useLocation()

    const { i18n } = useTranslation();

    const { theme } = useContext(ThemeContext);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme])

    const [showModal, setShowModal] = useState<null | { id: number, image: number }>(null);

    return (

        <ScreenContainer>

            {showModal !== null && <WorkModal showModal={showModal} setShowModal={setShowModal} />}

            <Suspense fallback={<LoadingComponent />}>

                <JobsGrid setShowModal={setShowModal} />

            </Suspense>

        </ScreenContainer>

    )
}
