import { memo, useEffect, useState } from "react"
import { IntroImagesProps } from "../interfaces/WorkInterfaces";
import styled from "styled-components";
import { colors, device, deviceHorizontal } from "../styles/stylesConfig";
import { useLogEvent } from "../hooks/useLogEvent";

const InfoContainer = styled.div` 
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
    margin: auto;

    & > h1 {
        font-size: 62px;
        font-weight: 800;
        letter-spacing: 5px;
        margin-bottom: 0rem;

        @media ${device.xxl} {

            @media ${deviceHorizontal.md} {
                font-size: 50px;
            }
            @media ${deviceHorizontal.sm} {
                font-size: 30px;
                margin-bottom: 3rem;
            }
            @media ${deviceHorizontal.xs} {
                font-size: 20px;
            }
        }
    
        @media ${device.md} {
            font-size: 60px;

            @media ${deviceHorizontal.sm} {
                font-size: 18px;
                margin-bottom: 0rem;
            }

        }
    
        @media ${device.sm} {
            font-size: 30px;
        }
    
        @media ${device.xs} {
            font-size: 30px;
        }
    }

    @media ${device.lg} {
        width: 75%;
    }

    @media ${device.md} {
        width: 80%;
        margin-top: 10vh;

        @media ${deviceHorizontal.sm} {
            margin-top: 0vh;
        }
    }

    &.active {
        & > h1 {
            animation: pulse 1.25s ease;
        }
    }

`


const Brands = styled.img`
    width: 300px;
    height: auto;
    object-fit: cover;
    position: absolute;
    right: 15vw;
    bottom: 5vh;

    &.active {
        animation: pulse 1.25s ease;
    }

    @media ${device.xl} {
        right: 13vw;
    }

    @media ${device.lg} {
        right: 0px;
        left: 0px;
        margin: auto;
        bottom: 10vh;
        width: 250px;
    }
    
    @media ${device.md} {
        width: 200px;
    }

    @media ${device.sm} {
        width: 180px;
        bottom: 12vh;
        right: 0px;
        left: 0px;
        margin: auto;
    }

    @media ${device.xs} {
        bottom: 13vh;
        left: 10vw;
        right: unset;
        margin: unset;
    }

`
//

const InfoExpositionContainer = styled.div` 
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    bottom: 0;
    margin: auto;

    @media ${device.lg} {
        top: 0px;
        bottom: 50px;
        width: 75%;
    }

    @media ${device.md} {
        width: 80%;
        
    }

    & > h1 {
        font-size: 38px;
        font-weight: 400;
        letter-spacing: 5px;

        @media ${device.xl} {
            font-size: 40px;

        }

        @media ${device.lg} {
            
            text-align: center;
            width: 100%;
        }
    
        @media ${device.md} {
            font-size: 24px;
        }
    
        @media ${device.sm} {
            font-size: 14px;
        }
    
        @media ${device.xs} {
            font-size: 10px;
        }
    }

    span {
        font-weight: 800;
    }

    &.active {
        & > h1 {
            animation: pulse 1.25s ease;
        }
    }

`

const BrandsExpositionContainer = styled.div` 
    height: 60%;
    position: absolute;
    right: 10vw;
    bottom: 0;
    top: 0;
    margin: auto;
    padding-top: 100px;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(2, 1fr);
    z-index: 3;

    &.active {
        animation: pulse 1.25s ease;
    }

    @media ${device.xl} {

    }

    @media ${device.lg} {
        height: auto;
        width: 70%;
        grid-template-rows: unset;
        grid-template-columns: repeat(2, 1fr);
        right: 0;
        left: 20px;
        top: unset;
        bottom: 10vh;
    }
    
    @media ${device.md} {
        
    }

    @media ${device.sm} {
        bottom: 30vh;
    }

    @media ${device.xs} {
        left: 30px;
    }

`

const BrandExposition = styled.img`

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.05, 0.85, 1);
            transform: scale3d(1.05, 0.85, 1);
  }
  40% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  65% {
    -webkit-transform: scale3d(1, 1.05, 1);
            transform: scale3d(1, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 1, 1);
            transform: scale3d(1.05, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  40% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  65% {
    -webkit-transform: scale3d(1, 1.05, 1);
            transform: scale3d(1, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 1, 1);
            transform: scale3d(1.05, 1, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}



    width: 300px;
    height: auto;
    object-fit: cover;
    justify-self: center;
    cursor: pointer;
    transition: all .3s ease;
    z-index: 30;

    /* &:last-of-type {
        width: 190px;
        margin-top: 30px;
    } */

    &.active {
        animation: pulse 1.25s ease;
    }

    &.jump {
        -webkit-animation: jello-horizontal 1s ease-in-out both;
        animation: jello-horizontal 1s ease-in-out both;
    }

    &:hover {
        transform: scale(1.05);
        animation: unset;
    }

    @media ${device.xl} {
        width: 250px;

        /* &:last-of-type {
            width: 190px;
            margin-top: 20px;
        } */
    }

    @media ${device.lg} {
        width: 200px;

        /* &:last-of-type {
            width: 120px;
            margin-top: 15px;
        } */
    }
    
    @media ${device.md} {
        width: 150px;

        /* &:last-of-type {
            width: 90px;
        } */
    }

    @media ${device.sm} {
        width: 100px;

        /* &:last-of-type {
            width: 60px;
            margin-top: 10px;
        } */
    }

    @media ${device.xs} {
        width: 80px;

        /* &:last-of-type {
            width: 40px;
            margin-top: 5px;
            margin-right: 15px;
        } */
    }

`

const BatevLogoContainer = styled.div` 
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media ${device.lg} {
        margin-bottom: 30px;
        align-items: center;
        align-self: center;

    }

    p {
        margin-left: 7px;
        font-size: 16px;

        @media ${device.lg} {
            margin-left: -20px;
        }

        @media ${device.md} {
            
        }

        @media ${device.sm} {
            font-size: 12px;
        }

        @media ${device.xs} {
        }

    }

    &.active {
        animation: pulse 1.25s ease;
    }

`

const BatevLogo = styled.img`
    width: 400px;
    height: auto;
    object-fit: cover;

    &.active {
        animation: pulse 1.25s ease;
    }


    @media ${device.lg} {
        width: 350px;
    }
    
    @media ${device.md} {
        
    }

    @media ${device.sm} {
        width: 250px;
    }

    @media ${device.xs} {
        width: 220px;
    }

`

const MoreInfoButton = styled.button`

    @-webkit-keyframes shake-horizontal {
        0%,
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70% {
            -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
        }
        20%,
        40%,
        60% {
            -webkit-transform: translateX(5px);
                    transform: translateX(5px);
        }
        80% {
            -webkit-transform: translateX(3px);
                    transform: translateX(3px);
        }
        90% {
            -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
        }
    }
    @keyframes shake-horizontal {
        0%,
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70% {
            -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
        }
        20%,
        40%,
        60% {
            -webkit-transform: translateX(5px);
                    transform: translateX(7px);
        }
        80% {
            -webkit-transform: translateX(3px);
                    transform: translateX(3px);
        }
        90% {
            -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
        }
    }


    color: white;
    font-size: 16px;
    background-color: #ff0c19;
    border: 1px solid white;
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.3);
    width: fit-content;
    height: fit-content;
    padding: 15px 30px;
    cursor: pointer;
    transition: all .3s ease;
    align-self: center;
    text-transform: uppercase;
    position: absolute;
    display: flex;
    z-index: 1;
    border-radius: 50px;
    bottom: 13vh;
    left: 25vw;
    right: 0;
    margin: auto;

    &.shake {
        -webkit-animation: shake-horizontal .75s ease-in-out both;
        animation: shake-horizontal .75s ease-in-out both;
    }

    &:hover {
        transform: scale(1.05);
        animation: unset;
    }

    &.active {
        animation: pulse 1.25s ease;
    }

    @media ${device.xl} {
        left: 20vw;
    }

    @media ${device.lg} {
        font-size: 14px;
        bottom: 22vh;
        left: 0;
    }

    @media ${device.md} {
        padding: 10px 25px;
        font-size: 12px;
        bottom: 25vh;

    }

    @media ${device.sm} {
        padding: 10px 20px;
        font-size: 10px;
        bottom: 20vh;
    }
`


interface Props {
    item: IntroImagesProps;
    animationActive: boolean;
}

const IntroImageItem = ({ item, animationActive }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const [bannerLoad, setbannerLoad] = useState(false);

    const [shake, setShake] = useState(false);

    const [jump, setJump] = useState(false);

    useEffect(() => {

        const handleShake = () => {

            setShake(true);

            setTimeout(() => {
                setShake(false);
            }, 750);

        }

        setTimeout(() => {

            handleShake();

        }, 2000);

        const intervaloShake = setInterval(() => handleShake(), 7000);

        return () => {

            clearInterval(intervaloShake);

        }

    }, []);

    useEffect(() => {

        const handleJump = () => {

            setJump(true);

            setTimeout(() => {
                setJump(false);
            }, 1000);

        }

        setTimeout(() => {

            handleJump();

        }, 4000);

        const intervaloJump = setInterval(() => handleJump(), 10000);

        return () => {

            clearInterval(intervaloJump);
        }

    }, []);

    return (

        <div
            key={item.id}
            className='introduction__image'
        >
            <img src={require(`../images/${item.image}`)}
                loading="eager"
                alt=""
                aria-hidden="true"
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                }}
                onLoad={() => { setbannerLoad(true) }}
            />

            {
                item.id === -1 && bannerLoad ? (

                    <>

                        <InfoExpositionContainer className={animationActive ? 'active' : ''} key={item.id}>

                            <BatevLogoContainer className={animationActive ? 'active' : ''}>

                                <BatevLogo
                                    src={require('../images/batev-logo.webp')}
                                    loading="eager"
                                    alt=""
                                    aria-hidden="true"
                                    onLoad={() => { setbannerLoad(true) }}
                                />

                                <p>Impulsando la industria</p>

                            </BatevLogoContainer>

                            <h1>Te invitamos a la</h1>

                            <h1 style={{ color: item.textColor }}><span>Expo Batev 24</span></h1>

                            <h1>26 al 29 de Junio</h1>

                            <h1><span>La Rural - Buenos Aires</span></h1>

                            <h1 style={{ color: item.textColor }}>¡Vamos a presentar</h1>

                            <h1><span>Nuestras aberturas</span></h1>

                            <h1 style={{ color: item.textColor }}><span>PVC</span> y <span>Aluminio</span>!</h1>

                        </InfoExpositionContainer>

                        <BrandsExpositionContainer>

                            <BrandExposition
                                className={`${animationActive ? 'active' : ''} ${jump ? 'jump' : ''}`}
                                src={require('../images/america-zemel-logo.webp')}
                                loading="lazy"
                                alt=""
                                aria-hidden="true"
                                referrerPolicy="no-referrer"
                                onClick={() => {
                                    handleLogEvent('click_logo_america_zemel_exposicion_batev_26/06/24');
                                    window.open("http://americazemel.com/", "_blank");
                                }}
                            />

                            <BrandExposition
                                className={`${animationActive ? 'active' : ''} ${jump ? 'jump' : ''}`}
                                src={require('../images/logo-blanco.png')}
                                loading="lazy"
                                alt=""
                                aria-hidden="true"
                                referrerPolicy="no-referrer"
                            />


                        </BrandsExpositionContainer>

                        <MoreInfoButton
                            className={`${animationActive ? 'active' : ''} ${shake ? 'shake' : ''}`}
                            onClick={() => {
                                handleLogEvent('click_mas_info_exposicion_batev_26/06/24');
                                window.open("https://www.batev.com.ar/web/wp-content/uploads/preacreditacion2024/", "_blank");
                            }}
                        >
                            Más información
                        </MoreInfoButton>

                    </>

                ) : (

                    <>

                        <InfoContainer className={animationActive ? 'active' : ''} key={item.id}>

                            <h1 style={{ color: item.textColor }}>{item.title}</h1>

                            <h1>{item.subtitle}</h1>

                        </InfoContainer>

                        <Brands
                            className={animationActive ? 'active' : ''}
                            src={require('../images/rehau-hydro.webp')}
                            loading="lazy"
                            alt=""
                            aria-hidden="true"
                        />

                    </>

                )
            }

        </div>

    )
}

const MemorizedIntroImageItem = memo(IntroImageItem);

export default MemorizedIntroImageItem;
