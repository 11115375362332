import styled from "styled-components"
import Title from "./Title"
import { device } from "../styles/stylesConfig"
import { memo, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { ProfileProps } from "../interfaces/WorkInterfaces"
import { ProductDataProps } from "../interfaces/TextsInterfaces"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border-bottom: 1px solid rgba(27, 48, 100, 0.3);
`

const ContentContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    @media ${device.lg} {
        width: 100%;

        & > div > h1 {
            text-align: center !important;
        }

    }
`

const ProfileContainer = styled.div`
    width: 90%;
    display: grid;
    align-items: start;
    gap: 80px;
    margin-top: 50px;

    @media ${device.lg} {
        gap: 0px;
        margin-top: 30px;
    }

    @media ${device.md} {
        margin-top: 0px;
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr) !important;
        row-gap: 50px;
        width: 70%;
    }
`

const Profile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ProfileTitle = styled.div`
    text-transform: uppercase;
    color: #fff;
    padding: 5px 15px;
    border-radius: 2px;
    background-color: #004bb5;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;

    @media ${device.lg} {
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media ${device.md} {
        font-size: 13px;
        margin-bottom: 20px;
        padding: 5px 10px;
    }

    @media ${device.sm} {
        padding: 5px 15px;
        font-size: 12px;
        margin-bottom: 20px;
    }

    @media ${device.xs} {
        margin-bottom: 20px;
    }
`

const ProfileDescription = styled.div`
    text-align: center;
    padding: 0px 10px;
    color: #004bb5;
    font-size: 13px;
    line-height: 20px;

    @media ${device.md} {
        font-size: 12px;
    }

 
`

const Icon = styled.img`
    width: 200px;
    height: auto;
    object-fit: contain;

    @media ${device.lg} {
        width: 150px;
    }

    @media ${device.md} {
        width: 120px;
    }



`


interface Props {
    title: string;
    profiles: ProductDataProps[];
}

const ProfilesSectionComponent = ({ title, profiles }: Props) => {

    const {
        theme: { colors, dark },
    } = useContext(ThemeContext);

    const location = useLocation();

    useEffect(() => {

        allAnimations();

        return () => {
            allAnimations()
        };

    }, [location.pathname])

    return (

        <Container>

            <ContentContainer>

                <Title
                    title={title}
                    titleColor="#004bb5"
                    textAlign="start"
                    titleLighter
                />

                <ProfileContainer style={{ gridTemplateColumns: `repeat(${profiles.length}, 1fr)` }}>

                    {
                        profiles.map((profile) => {

                            return (

                                <Profile key={profile.title}>

                                    <Icon src={require(`../images/${profile.image}`)} alt={profile.title} className="utils__fade-in-fast-top" />

                                    <ProfileTitle className="utils__fade-in-fast-top">{profile.title}</ProfileTitle>

                                    <ProfileDescription style={{ color: dark ? colors.text_light : colors.primary }} className="utils__fade-in-fast-top">{profile.description}</ProfileDescription>

                                </Profile>

                            )
                        })
                    }

                </ProfileContainer>

            </ContentContainer>


        </Container>

    )
}

const ProfilesSection = memo(ProfilesSectionComponent);

export default ProfilesSection;