import { styled } from 'styled-components';
import { device } from '../styles/stylesConfig';
import { useHistory } from 'react-router-dom';
import { useAnimations } from '../effects/Effects';
import { useLogEvent } from '../hooks/useLogEvent';
import { memo, useContext } from 'react';
import { ThemeContext } from '../contexts/theme/ThemeContext';

const LogoContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.75s;
    padding: 0;
    cursor: pointer;
`

const LogoImage = styled.img`
    width: 225px;
    height: auto;
    transition: all 0.3s ease-in-out;


    @media ${device.lg} {
        width: 180px;
        height: auto;
    }

    @media ${device.sm} {
        width: 140px;
        height: auto;
    }
`

const AppLogo = () => {

    const history: any = useHistory();

    const {
        handleClickGoHome
    } = useAnimations()

    const { handleLogEvent } = useLogEvent();

    const {
        theme: { dark },
    } = useContext(ThemeContext);

    return (

        <LogoContainer
            onClick={async () => {

                await history.push('/');

                await handleClickGoHome()

                handleLogEvent('click_logo');

            }}
        >

            <LogoImage
                src={require(`../images/logo${dark ? '-blanco' : ''}.png`)}
                alt='logo'
            />

        </LogoContainer>

    )
}

const AppLogoComponent = memo(AppLogo);

export default AppLogoComponent;