import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import Form from "../components/Form";
import { allAnimations } from "../effects/Observers"
import { useTranslation } from "react-i18next";
import ScreenContainer from "../components/ScreenContainer";
import { ThemeContext } from "../contexts/theme/ThemeContext";

export const ContactScreen = () => {

    const location = useLocation()

    const { i18n } = useTranslation();

    const { theme } = useContext(ThemeContext);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme])


    return (
        <ScreenContainer>

            <Form screen="Contact" />

        </ScreenContainer>
    )
}
