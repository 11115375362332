import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { memo } from "react"
import BrandsCarrousel from "./BrandsCarrousel"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const ContentContainer = styled.div`
    width: 90%;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    position: relative;
    background-color: #111;
    padding: 80px 5px;

    @media ${device.lg} {
        grid-template-columns: repeat(1, 1fr);
    }
`

const DataContainer = styled.div`
    width: fit-content;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media ${device.lg} {
        flex-direction: column;
        margin: 30px 0px;
        align-items: center;
    }

`

const DataTitle = styled.p`
    color: white;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 500;
    
    @media ${device.md} {
        text-align: center;
        font-size: 28px;
    }

    @media ${device.sm} {
        font-size: 28px;
    }
`

const TextInfo = styled.p`
    color: white;
    line-height: 20px;

    @media ${device.lg} {
        text-align: center;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const ActionInfo = styled.a`
    color: white;
    margin-bottom: 20px;
    cursor: pointer;
    line-height: 20px;

    &:hover {
        text-decoration: underline;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }
`

const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.lg} {
        align-items: start;
    }

`

const SocialButton = styled.button`
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: all .3s ease;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:hover {
        background-color: #004bb5;
    }
`

const SocialIcon = styled.img`
    width: 30px;
    height: 30px;

    @media ${device.md} {
        width: 25px;
        height: 25px;
    }

    @media ${device.sm} {

    }
`

const SocialName = styled.p`
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 18px;
    margin-left: 10px;

    @media ${device.md} {
        font-size: 16px;
    }

    @media ${device.sm} {
        font-size: 14px;
    }
`

const LocationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;

    @media ${device.lg} {
        align-items: center;
    }

    & > button:last-of-type img {
        height: 30px;

        @media ${device.md} {
            height: 25px;
        }

        @media ${device.sm} {
            height: 20px;
        }
    }

`

const LocationButton = styled.button`
    cursor: pointer;
    transition: all .3s ease;
    padding: 10px 0px;
`

const LocationIcon = styled.img`
    width: auto;
    height: 40px;

    @media ${device.md} {
        height: 35px;
    }

    @media ${device.sm} {
        height: 30px;
    }
`

const FooterComponent = () => {

    const { handleLogEvent } = useLogEvent();

    const { t } = useTranslation();

    return (

        <Container>

            <BrandsCarrousel />

            <ContentContainer>

                <DataContainer>

                    <DataTitle className="utils__fade-in-fast-top">{t('footer.location.title')}</DataTitle>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                    >Avenida General Juan Gregorio Lemos 4060,
                        <br />Ingeniero Adolfo Sourdeaux
                    </TextInfo>

                    <LocationContainer>

                        <LocationButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {
                                handleLogEvent('click_maps');
                                window.open("https://maps.app.goo.gl/UBgC8QjoBBXN8DHW6?g_st=com.google.maps.preview.copy", "_blank");
                            }}
                        >
                            <LocationIcon src={require('../images/maps.png')} alt="Google Maps" />

                        </LocationButton>

                        <LocationButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {

                                handleLogEvent('click_waze');
                                window.open("https://waze.com/ul/h69y79jzwe", "_blank")
                            }}
                        >

                            <LocationIcon src={require('../images/waze.png')} alt="Waze" />

                        </LocationButton>

                    </LocationContainer>

                </DataContainer>

                <DataContainer>

                    <DataTitle className="utils__fade-in-fast-top">{t('footer.numbers')}</DataTitle>

                    <ActionInfo
                        className="utils__fade-in-fast-top"
                        href="tel:+5491164224433"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_primer_telefono');
                        }}
                    >+54 011 6422-4433</ActionInfo>

                    <ActionInfo
                        className="utils__fade-in-fast-top"
                        href="tel:+5491134242807"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_segundo_telefono');
                        }}
                    >+54 011 3424-2807</ActionInfo>

                    <ActionInfo
                        className="utils__fade-in-fast-top"
                        href="tel:+5491126999990"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_tercer_telefono');
                        }}
                    >+54 011 2699-9990</ActionInfo>


                    <ActionInfo
                        className="utils__fade-in-fast-top"
                        href="mailto:ventas@aluviher.com.ar"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_correo');
                        }}
                    >ventas@aluviher.com.ar</ActionInfo>

                </DataContainer>

                <DataContainer>

                    <DataTitle className="utils__fade-in-fast-top">{t('footer.social.media')}</DataTitle>

                    <SocialContainer>

                        <SocialButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {
                                handleLogEvent('click_instagram');
                                window.open("https://www.instagram.com/aluviher_ok/", "_blank");
                            }}
                        >
                            <SocialIcon src={require('../images/ig.png')} alt="Instagram" />

                            <SocialName>Instagram</SocialName>

                        </SocialButton>

                        <SocialButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {

                                handleLogEvent('click_linkedin');
                                window.open("https://www.linkedin.com/company/aluviher/", "_blank")
                            }}
                        >

                            <SocialIcon src={require('../images/li.png')} alt="LinkedIn" />

                            <SocialName>LinkedIn</SocialName>

                        </SocialButton>

                        <SocialButton
                            className="utils__fade-in-fast-top"
                            onClick={() => {

                                handleLogEvent('click_facebook');
                                window.open("https://www.facebook.com/aluviher/", "_blank")
                            }}
                        >

                            <SocialIcon src={require('../images/fb.png')} alt="Facebook" />

                            <SocialName>Facebook</SocialName>

                        </SocialButton>

                    </SocialContainer>

                </DataContainer>

            </ContentContainer>

        </Container>

    )
}

const Footer = memo(FooterComponent);

export default Footer;