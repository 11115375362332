import { Provider } from "react-redux";
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
import { AppState } from "./components/AppState";

export const App = () => {

  return (

    <AppState>

      <Provider store={store}>

        <AppRouter />

      </Provider>

    </AppState>
    
  );
}


