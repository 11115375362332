import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import Title from "./Title"
import { memo, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const ContentContainer = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`
const Subtitle = styled.p<{ color: string }>`
    width: 50%;
    text-align: center;
    line-height: 30px;
    font-family: 'Montserrat', sans-serif;
    color: #2d2d2d;
    color: ${({ color }) => (color)};

    @media ${device.lg} {
        width: 80%;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        line-height: 25px;
    }

    @media ${device.xs} {
        line-height: 20px;
        font-size: 10px;
    }
`


const InfoContainer = styled.div`
    margin: 100px 0 200px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media ${device.lg} {
        width: 90%;
        margin: 100px 0 150px;
    }

    @media ${device.md} {
        margin: 100px 0 120px;
        
    }

    @media ${device.sm} {
        margin: 70px 0 100px;
    }

`

const Card = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 100px;
        height: 100px;
    }

    @media ${device.md} {
        width: 70px;
        height: 70px;
    }

    @media ${device.sm} {
        width: 40px;
        height: 40px;
    }

    @media ${device.xs} {
        width: 35px;
        height: 35px;
    }
`


const CardRehau = styled(Card)`
    width: 210px;
    height: 210px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 180px;
        height: 180px;
    }

    @media ${device.md} {
        width: 130px;
        height: 130px;
    }

    @media ${device.sm} {
        width: 80px;
        height: 80px;
    }

    @media ${device.xs} {
        width: 60px;
        height: 60px;
    }
`


const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

`

const WhoAreWeComponent = () => {

    const location = useLocation();

    const { t } = useTranslation();

    const data: any = t('about.descriptions', { returnObjects: true });

    const {
        theme: { colors, dark },
    } = useContext(ThemeContext);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    return (

        <Container>

            <ContentContainer>

                <Title
                    title={t('about.title')}
                />

                {
                    data.map((value: string) => {
                        return (
                            <Subtitle color={colors.text_light} key={value} className="utils__fade-in-fast-top">{value}</Subtitle>
                        )
                    })
                }

                <InfoContainer>

                    <Card
                        key={3}
                        className="utils__fade-in-fast-right"
                        onClick={async () => {

                        }}
                    >
                        <Image src={require(`../images/hydro${dark ? '-blanco' : ''}.png`)} alt='hydro' loading="lazy" />

                    </Card>

                    <Card
                        key={4}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require(`../images/aluar${dark ? '-blanco' : ''}.png`)} alt='aluar' loading="lazy" />

                    </Card>



                    <Card
                        key={5}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require('../images/ok-industrial.png')} alt='ok-industrial' loading="lazy" />

                    </Card>

                    <Card
                        key={6}
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                        }}
                    >
                        <Image src={require('../images/gu.png')} alt='gu' loading="lazy" />

                    </Card>
                    <CardRehau
                        key={7}
                        className="utils__fade-in-fast-left"
                        onClick={async () => {

                        }}
                    >

                        <Image src={require(`../images/rehau${dark ? '-blanco' : ''}.png`)} alt='rehau' loading="lazy" />

                    </CardRehau>
                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}

const WhoAreWe = memo(WhoAreWeComponent);

export default WhoAreWe;