
type ThemeAction = { type: 'set_light_theme' } | { type: 'set_dark_theme' };

interface Color {
    primary: string;
    primary_light: string;
    primary_dark: string;
    background: string;
    background_light: string;
    shadow: string;
    text: string;
    text_opposite: string;
    text_light: string;
    text_light_opposite: string;
    border: string;
    neutral: string;
    label: string;
    disabled: string;
    disabled_light: string;
    negativeOption: string;
    positiveOption: string;
    pass: string;
    error: string;
    info: string;
    warning: string;
}

export interface ThemeState {
    currentTheme: 'light' | 'dark';
    dark: boolean;
    colors: Color
}

export const lightTheme: ThemeState = {
    currentTheme: 'light',
    dark: false,
    colors: {
        primary: '#004bb5',
        primary_light: '#0090FF',
        primary_dark: '#2C3357',
        background: '#f2f3f4',
        background_light: 'white',
        shadow: 'black',
        text: '#2e2d2d',
        text_opposite: 'white',
        text_light: '#0b1216',
        text_light_opposite: '#f1f5f7',
        border: '#e1e1e1',
        neutral: '#5b8be4',
        label: '#555',
        disabled: '#555',
        disabled_light: '#bbbbbb',
        negativeOption: '#790000',
        positiveOption: '#316130',
        pass: '#339731',
        error: 'red',
        info: '#5b8be4',
        warning: 'orange',
    },
};

export const darkTheme: ThemeState = {
    currentTheme: 'dark',
    dark: true,
    colors: {
        primary: '#004bb5',
        primary_light: '#0090FF',
        primary_dark: '#2C3357',
        background: '#111',
        background_light: 'black',
        shadow: '#777777',
        text: 'white',
        text_opposite: 'black',
        text_light: '#ddd',
        text_light_opposite: '#2e2d2d',
        border: '#252525',
        neutral: '#5b8be4',
        label: '#999',
        disabled: '#999',
        disabled_light: '#bbbbbb',
        negativeOption: '#790000',
        positiveOption: '#316130',
        pass: '#339731',
        error: 'red',
        info: '#5b8be4',
        warning: 'orange',
    },
};

export const themeReducer = (
    state: ThemeState,
    action: ThemeAction,
): ThemeState => {
    switch (action.type) {
        case 'set_light_theme':
            return { ...lightTheme };
        case 'set_dark_theme':
            return { ...darkTheme };
        default:
            return state;
    }
};
