import { css, styled } from 'styled-components';
import { device as dimension } from '../styles/stylesConfig';
import { CSSProperties, memo } from 'react';


const Container = styled.div<{ device: 'mobile' | 'desktop' }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease-in;
    position: relative;

    ${({ device }) => device === 'desktop' ? css`
        display: flex;

        @media ${dimension.lg} {
            display: none;
        }

    `: css`
        display: none;

        @media ${dimension.lg} {
            display: flex;
        }
    `}


`

const Image = styled.img`
    height: 20px;
    width: 30px;
    margin: 0 5px;
    object-fit: cover;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);

    @media ${dimension.xl} {
        height: 17.5px;
        width: 25px;
    }

    @media ${dimension.sm} {
        height: 15px;
        width: 20px;
    }
`

interface Props {
    device: 'mobile' | 'desktop'
    style?: CSSProperties
}

const CountryFlagsComponent = ({ device, style }: Props) => {

    return (

        <Container style={{ ...style }} device={device}>

            <Image
                src={require('../images/arg-flag.webp')}
                alt="Argentina"
            />

            <Image
                src={require('../images/alm-flag.png')}
                alt="Alemania"
            />

            <Image
                src={require('../images/nor-flag.webp')}
                alt="Noruega"
            />

        </Container>

    )
}

const CountryFlags = memo(CountryFlagsComponent);

export default CountryFlags;