document.addEventListener("DOMContentLoaded", function () {
    
    allAnimations()
});


////////// FADE IN BOTTOM //////////

export function scrollFadeInBottom() {

    const faders = document.querySelectorAll('.utils__fade-in-bottom');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastBottom() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-bottom');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN TOP //////////

export function scrollFadeInTop() {

    const faders = document.querySelectorAll('.utils__fade-in-top');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastTop() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-top');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN CENTER //////////

export function scrollFadeInCenter() {

    const faders = document.querySelectorAll('.utils__fade-in-center');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastCenter() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-center');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN RIGHT //////////

export function scrollFadeInRight() {

    const faders = document.querySelectorAll('.utils__fade-in-right');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastRight() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-right');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

////////// FADE IN LEFT //////////

export function scrollFadeInLeft() {

    const faders = document.querySelectorAll('.utils__fade-in-left');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

export function scrollFadeInFastLeft() {

    const faders = document.querySelectorAll('.utils__fade-in-fast-left');

    const appearOptions = {
        threshold: 0.5
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {

        entries.forEach((entry) => {

            if (!entry.isIntersecting) {
                return
            } else {
                entry.target.classList.add('appear');
                appearOnScroll.unobserve(entry.target);
            }

        })

    }, appearOptions)

    faders.forEach((fader) => {
        appearOnScroll.observe(fader)
    })
}

////////////////////////////////

export function fadeInBlurImages() {

    const imagenes = document.querySelectorAll('.imgs');

    imagenes.forEach((imagen) => {
        imagen.addEventListener('mouseover', () => {
            imagenes.forEach((img) => {
                if (img !== imagen) {
                    img.style.filter = 'blur(2px)';
                    img.classList.add('dark');
                }
            });
        });

        imagen.addEventListener('mouseout', () => {
            imagenes.forEach((img) => {
                img.style.filter = 'none';
                img.classList.remove('dark');
            });
        });
    });

}

/////////////////////////////////////


export const allAnimations = () => {
    scrollFadeInTop();
    scrollFadeInFastTop();

    scrollFadeInBottom();
    scrollFadeInFastBottom();

    scrollFadeInCenter();
    scrollFadeInFastCenter();

    scrollFadeInRight();
    scrollFadeInFastRight();

    scrollFadeInLeft();
    scrollFadeInFastLeft();

    fadeInBlurImages();
}
