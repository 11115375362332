import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { memo } from "react"

const Container = styled.div`
    width: 100%;
    height: 75vh;
    padding-top: 16vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @media ${device.lg} {
        padding-top: 15vh;
        height: 50vh;
    }
    @media ${device.md} {
        height: 40vh;
    }
`

const Image = styled.div`
    width: 100%;
    height: 80%;
    background: url(${require('../images/job3.webp')}) center no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media ${device.lg} {
        width: 80%;
    }

`


const AboutImageComponent = () => {

    return (

        <Container>

            <Image className="utils__fade-in-fast-top" />

        </Container>

    )
}

const AboutImage = memo(AboutImageComponent);

export default AboutImage;