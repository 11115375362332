import React, { createContext, useEffect, useReducer } from 'react';
import { themeReducer, ThemeState, lightTheme } from './ThemeReducer';

interface ThemeContextProps {
    theme: ThemeState;
    setDarkTheme: () => void;
    setLightTheme: () => void;
}

export const ThemeContext = createContext({} as ThemeContextProps);

export const ThemeProvider = ({ children }: any) => {

    const [theme, dispatch] = useReducer(
        themeReducer,
        lightTheme,
    );

    useEffect(() => {
        const localScheme = async () => {
            let scheme;

            try {
                scheme = await localStorage.getItem('local_theme');
            } catch (error) {
                scheme = 'light';
            }

            if (scheme === 'dark') {
                setDarkTheme();
            } else {
                setLightTheme();
            }
        };

        localScheme();
    }, []);

    const setDarkTheme = () => {
        dispatch({ type: 'set_dark_theme' });
    };

    const setLightTheme = () => {
        dispatch({ type: 'set_light_theme' });
    };

    return (
        <ThemeContext.Provider
            value={{
                theme,
                setDarkTheme,
                setLightTheme,
            }}>
            {children}
        </ThemeContext.Provider>
    );
};
