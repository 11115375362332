import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { memo, useEffect, useState } from "react"
import Slider from "react-slick"
import { BrandsCarrouselList } from "../utils/Jsons"
import { BrandsCarrouselProps } from "../interfaces/WorkInterfaces"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
`
const List = styled.div`

    @keyframes desplazar {
        from {
        transform: translateX(0);
        }
        to {
            transform: translateX(-40%);
        }
    }

    display: flex;
    animation: desplazar 60s linear infinite;

    @media ${device.lg} {
        animation: desplazar 50s linear infinite;
    }

    @media ${device.md} {
        animation: desplazar 50s linear infinite;
    }

    @media ${device.sm} {
        animation: desplazar 50s linear infinite;
    }

    @media ${device.xs} {
        animation: desplazar 50s linear infinite;
    }


`

const ImageContainer = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 30px;
    align-self: center;

    @media ${device.sm} {
        height: 100px;
        width: 100px;
        margin: 0 15px;
    }
`

const Image = styled.img`
    height: 100px;
    width: 100px;
    object-fit: contain;
    position: absolute;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

`

const CustomTitle = styled.p`
    width: 90%;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 15px;

    @media ${device.lg} {
        font-size: 20px;
    }

    @media ${device.md} {
        font-size: 16px;
    }

    @media ${device.sm} {
        margin-bottom: 10px;
        font-size: 12px;
    }



`

const BrandsCarrouselComponent = () => {

    const { t } = useTranslation();

    return (

        <Container>

            <CustomTitle>{t('footer.partners')}</CustomTitle>

            <List>

                {

                    [...BrandsCarrouselList, ...BrandsCarrouselList, ...BrandsCarrouselList, ...BrandsCarrouselList, ...BrandsCarrouselList].map((item: BrandsCarrouselProps) => {
                        return (

                            <ImageContainer key={item.id} style={{ ...item.customStyle }}>

                                <Image src={item.image} alt={item.id.toString()} loading="lazy" style={{ ...item.customStyle, }} />

                            </ImageContainer>

                        )
                    })

                }


            </List>

        </Container>

    )
}

const BrandsCarrousel = memo(BrandsCarrouselComponent);

export default BrandsCarrousel;