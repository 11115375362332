import ReactDOM from 'react-dom';
import { App } from './App';
import './styles/styles.scss';
import './effects/Observers';
import './i18n';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

